import logo from './logo.svg';
import React from 'react';
import Slider from "react-slick";

import axios from 'axios';
import Toggle from 'react-toggle'
import swal from 'sweetalert';
import $ from 'jquery';

import MyGlobleSetting from './MyGlobleSetting';



class Slide extends React.Component {


  render() {
console.log(this.props);
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div className="App">
        <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-4" style={{padding:"20px"}}>
      <Slider {...settings}>
        {this.props.categories.map((obj, key) => {
          return (
  <div className="slide-h3" style={{textAlign:"center"}}>
  <h3>{obj.name}</h3>
</div>)

        })}
    
     
    </Slider>
</div>
    </div>
      </div>

    );
  }
}

export default Slide;
