import logo from './logo.svg';
import React from 'react';


import axios from 'axios';
import Toggle from 'react-toggle'
import swal from 'sweetalert';
import $ from 'jquery';

import MyGlobleSetting from './MyGlobleSetting';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";


axios.interceptors.request.use(function (config) {

  // spinning start to show
  // UPDATE: Add this code to show global loading indicator
  document.body.classList.add('loading-indicator');

  const token = window.localStorage.token;
  if (token) {
    config.headers.Authorization = `token ${token}`
  }
  return config
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {

  // spinning hide
  // UPDATE: Add this code to hide global loading indicator
  document.body.classList.remove('loading-indicator');

  return response;
}, function (error) {
  return Promise.reject(error);
});



class ScanQR extends React.Component {


  render() {


    return (
      <div className="App">






        <div className="site-content">

          <header className="site-header header-style-one intro-element header-absolute pages-header" style={{ background: "white", borderBottom: "2px solid lightgrey" }}>
            <div className="navigation-area">
              <div className="container">
                <div className="row ">
                  <div className="col-12">
                    <div className="site-navigation">
                      <div className="site-branding">
                        <h4 >
                          Scan QR
                        </h4>
                      </div>

                      <div className="header-right-area">





                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>


          </header>


          <div className="food-menu-block style-nine">
            <div className="food-cat-title-area bg-image bg-overlay bg-parallax2" style={{ padding: " 88px 2px 15px 2px", background: "black" }}>
              <h2 className="food-cat-title">  {localStorage.getItem("resname")}</h2>


            </div>

            <div className="food-menu-block-main ">

              <div className="container">

                <div className="row m-2 mt-5">
                  <div className="col-md-12">
                    <center>
                      <h4 style={{ textAlign: "center", width: "100%" }}>You have not selected any table. <br />Scan the QR code first. </h4>

                    </center>
                  </div>
                </div>

              </div>

            </div>
          </div>











        </div>





      </div>
    );
  }
}

export default ScanQR;
