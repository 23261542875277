import logo from './logo.svg';
import React from 'react';


import axios from 'axios';
import Toggle from 'react-toggle'
import swal from 'sweetalert';
import $ from 'jquery';

import ReactTooltip from 'react-tooltip';

import MyGlobleSetting from './MyGlobleSetting';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom";


axios.interceptors.request.use(function (config) {

    // spinning start to show
    // UPDATE: Add this code to show global loading indicator
    document.body.classList.add('loading-indicator');
  
    const token = window.localStorage.token;
    if (token) {
       config.headers.Authorization = `token ${token}`
    }
    return config
  }, function (error) {
    return Promise.reject(error);
  });
  
  axios.interceptors.response.use(function (response) {
  
    // spinning hide
    // UPDATE: Add this code to hide global loading indicator
    document.body.classList.remove('loading-indicator');
  
    return response;
  }, function (error) {
    return Promise.reject(error);
  });



class View extends React.Component {


    constructor(props) {
        super(props);
   
    
        this.state = {
          cartdata:[],
          openitem:'',
          tabledata:'',
          method:0,
          textarea:'',
          orderdata:[],
          ticketinfo:''
        };
      }

      toggleswitch(id,val) {
        //this.setState({ selectedbtn[id]: response.data.data });
  }


  checkpin()
  {

    var token=localStorage.getItem("devicetoken");
    var tabledata = localStorage.getItem("tableid");

    var pincode=$(".pincode").val();

    const data = {
    
        token: token,
        tableid: tabledata,
       
      }
    axios.post(MyGlobleSetting.api+'/api/getorder', data).then((response) => {
           
     if(response.data.success==true) 
     
     {
     if(response.data.data != null)
      this.setState({ orderdata: response.data.data.tableitem , ticketinfo: response.data.data});

    }
    else
    {
      swal({
       
        text: "Invalid PIN",
        icon: "error"
       
      });
    }



            });
         

  }


  goto_page(page)
  {
  
    this.props.history.push(page)
  }




  getcart()
  {

    var token=localStorage.getItem("devicetoken");
    const data = {
    
        token: token
      }
    axios.post(MyGlobleSetting.api+'/api/getcart', data).then((response) => {
           
        this.setState({ cartdata: response.data.data });


            });




  }

  

     




    componentDidMount(){

           
                    
      var token = localStorage.getItem("devicetoken");
      var tabledata = localStorage.getItem("tableid");

      var currencyIcon = localStorage.getItem("currencyIcon");
      localStorage.clear();

   localStorage.setItem("devicetoken",token);
   localStorage.setItem("tableid",tabledata);
   localStorage.setItem("currencyIcon",currencyIcon);  
     



  
        this.tabledata();
        this.checkpin();
 
      }


      tabledata()
      {


        const data = {
    
          id: localStorage.getItem("tableid")
        }
      axios.post(MyGlobleSetting.api+'/api/checktable', data).then((response) => {
             
      
        if(response.data.success==true)
        {
      
          this.setState({tabledata:response.data.data});
         
         
        }
        else
        {
          swal({
       
            text: "You have not select any table, Please scan QR",
           
          });
        }
        
      
      
              });



      }


      goto_delete(id)
      {


        const customer = {
            id: id
          }
        
    
    
          axios.post(MyGlobleSetting.api+'/api/deletecartbyid', customer).then((response) => {

            swal({
       
                text: "Item is successsfully deleted from cart !!",
                icon: "success"
               
              });


            this.getcart();
        });



      }

      goto_edit(id)
      {
        this.props.history.push({
          pathname: '/edit',
          search: '?id=' + id,
          state: { id: id}
        })
      }
    


    render(){

     var totprice=0; 

     var alltotprice=0;
  return (
    <div className="App">






          <div className="site-content">
      
 
          <header className="site-header header-style-one intro-element header-absolute pages-header" style={{background:"white",borderBottom:"2px solid lightgrey"}}>
            <div className="navigation-area">
                <div className="container">
                    <div className="row ">
                        <div className="col-12">
                            <div className="site-navigation">
                                <div className="site-branding">
                                    <h4 >
                                        View Order 
                                    </h4>
                                </div>

                                <div className="header-right-area">
                                   
                                    
                                   <div className="header-card-area" style={{marginRight:"15px"}}>
                                   <button type="button"  onClick={()=>this.goto_page('/')} class="btn btn-primary">Back</button>
                                   </div>
                                

                                  
                               </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           
        </header>






        <div class="shopping-cart-block pl-1 pr-1" style={{paddingTop:"70px"}}>
          
        {/* {this.state.orderdata=='' || this.state.orderdata == null ? 
            
        <div class="cart-total-area mt-3">
                          

             



        <div class="text-center">

<input type="text" className="form-control pincode" placeholder="Ënter Pin" />
  <button type="button" class="btn btn-default mt-2 ml-1 mr-1" onClick={()=>this.checkpin()} >View</button> 
  
</div>

</div>

                        : '' } */}


                        {this.state.orderdata!='' && this.state.orderdata != null ? this.state.orderdata.map((data, i) => {

var cls="container p-0 mt-3";




var colrowclas="row m-2 mb-3 display-none";

if(this.state.openitem==data.id)
colrowclas="row m-2 mb-3";
totprice=totprice+(parseFloat(data.price)*parseFloat(data.qty));

var catdata=[];
var catid=[];
var c=0;
if(data.token_attributes!='' && data.token_attributes != null)
{
data.token_attributes.map((subdatanew, i) => {

  if(catid.includes(subdatanew.modifier_id)==false)
  {
    catid[c]=subdatanew.modifier_id;
    catdata[c]=subdatanew.modifier_name;
    c++;
  }
});

}

var st = data.price;
return (

<div className={cls} style={{border:"1px solid grey"}}>

<div className=" p-2" style={{background:"lightgrey",fontWeight:"700"}} onClick={()=>this.setState({openitem:data.id})}>


<h5 style={{width:"100%",marginBottom:"0px"}}>{data.qty} X {data.name} <span style={{float:"right"}}>{localStorage.getItem("currencyIcon")}  {data.totprice.toFixed(2)}</span></h5>


</div>


<div className={colrowclas} >


<div className="col-md-12 p-0 pl-2 pr-2">


<h5 style={{width:"100%",marginBottom:"0px"}}> 
<span style={{float:"right"}}> Base :&nbsp; &nbsp;  {localStorage.getItem("currencyIcon")}  {data.price.toFixed(2)}</span>


{data.item_surcharge!='' && data.item_surcharge != null  && data.item_surcharge.length>0 ? data.item_surcharge.map((attr, i) => {

if(attr.name!= '' && attr.name != null) {
var val=0;
var val1 = 0;
  if(attr.type==0)
  {
    val = data.qty*attr.value;
    val1 = attr.value;
  } else {
    val = (parseFloat(data.price)*parseFloat(data.qty)*attr.value)/100;
    val1 = (parseFloat(data.price)*attr.value)/100;
  }
st += val1;
  return (
<span>


<br/>
<span style={{float:"right"}}> {attr.name} {attr.type == 1 ? "("+attr.value+"%)":"" } :&nbsp; &nbsp;  {localStorage.getItem("currencyIcon")}  {val1.toFixed(2)}</span>
</span> 
  )
}
}):""}



</h5>
</div>



{catdata!='' && catdata != null ? catdata.map((cat, i) => {

var cls="container p-0 mt-3";




return (



<div className={cls} style={{border:"1px solid grey"}}>

<div className=" p-2" style={{background:"#e5e5e5",fontWeight:"700"}} >


<h5 style={{width:"100%",marginBottom:"0px"}}> {catdata[i]}</h5>


</div>
<table className="table" style={{marginBottom:"0px"}}>



{data.token_attributes!='' && data.token_attributes != null ? data.token_attributes.map((subdata, j) => {

var cls="container p-0 mt-1";



if(catid[i]==subdata.modifier_id)
{
  st += (subdata.qty*subdata.price);
return (




<tr>
<th style={{textAlign:"left"}}>{subdata.qty} X {subdata.name} </th>

<th style={{textAlign:"right"}}>{localStorage.getItem("currencyIcon")} {(subdata.qty*subdata.price).toFixed(2)} </th>




</tr>





)
}
}) : ""}

</table>


</div>





)

}) : ""}







<div class="col-md-12 p-0 pl-2 pr-2" style={{marginTop:"5px"}}>
    <h5 style={{width:"100%",color:"grey"}}>
<span style={{float:"right",padding:"0px !important"}}>Sub Total: <span>{(st).toFixed(2)}</span></span><br/>
<span style={{float:"right",padding:"0px !important",border:"none"}}>Total: <span>{(data.totprice).toFixed(2)}</span></span>
</h5>
  </div>
 




</div>




</div> 

);
}) : ""}

                


{this.state.ticketinfo!='' && this.state.ticketinfo != null ? 
            
            <div class="cart-total-area mt-3">
                              
    
                              <div class="cart-total-info">
                                <table class="table pb-4" style={{borderBottom:"1px solid grey"}}>
                                    <tr>
                                        <th style={{textAlign:"left",fontSize:"18px"}}>Sub Total</th>
                                        <th style={{textAlign:"right",fontSize:"18px"}}>{localStorage.getItem("currencyIcon")}  {this.state.ticketinfo.main_total}</th>
                                    </tr>


                                    {this.state.ticketinfo.main_total>0 && this.state.ticketinfo.tabletax!='' && this.state.ticketinfo.tabletax != null ? this.state.ticketinfo.tabletax.map((t, i) => {

if(alltotprice==0)
{
  alltotprice=this.state.ticketinfo.main_total;
}
var txt='';
var tamt=t.value;
  if(t.type==1)
  {
    txt='('+t.value+'% of '+alltotprice.toFixed(2)+')';

    tamt=(alltotprice*t.value)/100;
  }


 

return (
<tr>
<th style={{textAlign:"left",fontSize:"18px"}}>{t.name}   {txt} </th>
<th style={{textAlign:"right",fontSize:"18px"}}>{localStorage.getItem("currencyIcon")}  {tamt.toFixed(2)}</th>
</tr>




                                             )         }): '' }


{this.state.ticketinfo.tax_type == 1 ?
<tr>
<th style={{textAlign:"left",fontSize:"18px"}}>Tax included in Total </th>
<th style={{textAlign:"right",fontSize:"18px"}}>{localStorage.getItem("currencyIcon")}  { (this.state.ticketinfo.main_total - ( this.state.ticketinfo.main_total * (100 / (100 + this.state.ticketinfo.tax_per)))).toFixed(2)}</th>
</tr>
: "" }

                                    <tr>
                                        <th style={{textAlign:"left",fontSize:"18px"}}> Total</th>
                                        <th style={{textAlign:"right",fontSize:"18px"}}>{localStorage.getItem("currencyIcon")}  {this.state.ticketinfo.grand_total.toFixed(2)}</th>
                                    </tr>

                                </table>
                                
                                
                                <h3 class="cart-total-amount mt-2"><span>Order Info</span> </h3>
                            </div>

                            <div class="cart-total-info">
                                <table class="table pb-4" style={{borderBottom:"1px solid grey"}}>
                                    <tr>
                                        <th style={{textAlign:"left",fontSize:"18px"}}>Floor</th>
                                        <th style={{textAlign:"right",fontSize:"18px"}}>{this.state.ticketinfo.floor_name}</th>
                                    </tr>

                                    <tr>
                                        <th style={{textAlign:"left",fontSize:"18px"}}>Table</th>
                                        <th style={{textAlign:"right",fontSize:"18px"}}>{this.state.ticketinfo.table_name}</th>
                                    </tr>


                                </table>

                                </div>

    
    </div>
    
                            : <center><br/><p>No Order</p></center> }



            </div>
</div>





    </div>
  );
}
}

export default View;
