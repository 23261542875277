import logo from './logo.svg';
import React from 'react';



import axios from 'axios';
import Toggle from 'react-toggle'
import swal from 'sweetalert';
import $ from 'jquery';

import MyGlobleSetting from './MyGlobleSetting';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom";


axios.interceptors.request.use(function (config) {

    // spinning start to show
    // UPDATE: Add this code to show global loading indicator
    document.body.classList.add('loading-indicator');
  
    const token = window.localStorage.token;
    if (token) {
       config.headers.Authorization = `token ${token}`
    }
    return config
  }, function (error) {
    return Promise.reject(error);
  });
  
  axios.interceptors.response.use(function (response) {
  
    // spinning hide
    // UPDATE: Add this code to hide global loading indicator
    document.body.classList.remove('loading-indicator');
  
    return response;
  }, function (error) {
    return Promise.reject(error);
  });



class Edit extends React.Component {


    constructor(props) {
        super(props);
   
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);

        this.state = {
          categories: '',
          selectedcategory:'',
          menudata:'',
          subcategorydata:'',
          selectedsubcategory:'',
          modal:'',
          modifierdata:'',
          selectedmenu:'',
          selectedmodifierdata:null,
          selectedbtn:[],
          checked: false,
          localtoken:0,
          cartdata:[],
          selectedcart:'',
          mainqty:1,
          textarea:'',
        };
      }

      toggleswitch(id,val) {
        //this.setState({ selectedbtn[id]: response.data.data });
  }

  subqty2() {

    var value=parseInt(this.state.mainqty);   
 value=value-1;
 
 if(value<1)
 value=1;
 
 
     this.setState({ mainqty:value});
   }
 
 
   addqty2() {
     var value=parseInt(this.state.mainqty);   
 value=value+1;
 
     this.setState({ mainqty:value});
   }
 
 
  openmodaloption()
  {
    swal({
       
      text: "Add option in categories !!",
     
    });
  }
  goto_page(page)
  {
  
    this.props.history.push(page)
  }



  handleChange2(e) {

    this.setState({ checked:false});


    swal({
       
        text: "You can not select more option !!",
       
      });
   
  }


  
  subqty(name,value) {
    value=parseInt(value);   
value=value-1;

if(value<1)
value=1;



localStorage.setItem(name, value);

    this.setState({ checked:false});
  }


  getcart()
  {

    var token=localStorage.getItem("devicetoken");
    const data = {
    
        token: token
      }
    axios.post(MyGlobleSetting.api+'/api/getcart', data).then((response) => {
           
        this.setState({ cartdata: response.data.data });


            });




  }

  addqty(name,value) {
    

    value=parseInt(value);
    value=value+1;
    
  
    
    localStorage.setItem(name, value);
        
   


        this.setState({ checked:true});
      }



      handleSubmit(event) {
    

        var data = $('.cartform').serializeArray().reduce(function(obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});

         
     
      
          axios.post(MyGlobleSetting.api+'/api/addtocart', data).then((response) => {
           

            swal({
       
                text: "Item is successsfully added in cart !!",
                icon: "success"
               
              });
              
              
              var token = localStorage.getItem("devicetoken");
              var tabledata = localStorage.getItem("tableid");
        
              var currencyIcon = localStorage.getItem("currencyIcon");
              localStorage.clear();
        
           localStorage.setItem("devicetoken",token);
           localStorage.setItem("tableid",tabledata);
           localStorage.setItem("currencyIcon",currencyIcon);  




              this.getcart();

              this.props.history.push('/cart')

                });




     
        event.preventDefault();
      }


  
  handleChange(e) {
     

    if(e.target.checked==true)
    localStorage.setItem(e.target.name, 1);

    if(e.target.checked==false)
    localStorage.setItem(e.target.name, 0);




    console.log(e.target.checked);
    this.setState({ checked:false});
  }





  UNSAFE_componentWillMount() {

    this.setState({localtoken: localStorage.getItem("devicetoken") });
   
    if (this.props.history != undefined && this.props.history != '' && this.props.history.location != undefined && this.props.history.location.state != undefined && this.props.history.location.state.id != undefined && this.props.history.location.state.id != '') {
      //console.log(this.props.history)


    
      
      const customer = {
        id: this.props.history.location.state.id
      }
    


      axios.post(MyGlobleSetting.api+'/api/getcartbyid', customer).then((response) => {
           
        this.setState({  textarea: response.data.data.textarea, mainqty: response.data.data.qty, selectedcart: response.data.data,selectedmenu: response.data.data.menu, modifierdata: response.data.data.menu.menumodifier});


       if(this.state.modifierdata!='' && this.state.modifierdata != null)
       {
        var selectopt= JSON.parse(response.data.data.data);
        this.state.modifierdata.map((subdata, i) => {


          
if(this.state.selectedmodifierdata=='' || this.state.selectedmodifierdata==null)
{
  this.setState({selectedmodifierdata:subdata.id});
}



            var newdata= JSON.parse(subdata.modifier_data);

            if( (newdata.button_type == '1' || newdata.button_type == '2' ) && newdata.button_name != null && newdata.button_name != undefined && newdata.button_name != '')

{
    
    
    (newdata.button_name).map((btn, b) => {


        

        var toggleclass='toggle_'+subdata.id+'_'+b;

        var qtyclass='qty_'+subdata.id+'_'+b;
       

   

    if(selectopt[toggleclass]!=undefined && selectopt[toggleclass]!=null && selectopt[toggleclass]==1)
    {
     
      localStorage.setItem(toggleclass, selectopt[toggleclass]);
    }

    if(selectopt[qtyclass]!=undefined && selectopt[qtyclass]!=null && selectopt[toggleclass]==1)
    {
    
      localStorage.setItem(qtyclass, selectopt[qtyclass]);
    }

    

    this.setState({ checked:false});

    });


}






        });

    }



            });

    }



  }







   


    render(){
        var totprice=0;
        if(this.state.selectedmenu!=undefined && this.state.selectedmenu!=null && this.state.selectedmenu!='')
        var totprice=parseFloat(this.state.selectedmenu.price);
var sur = 0;
        var selectopt=1;
  return (
    <div className="App">





          <div className="site-content">
    <form onSubmit={this.handleSubmit} className="cartform">
      
        {this.state.selectedmenu!='' && this.state.selectedmenu != null ? 
        <div className="food-menu-block style-nine">

<div className="row topbar">
                        <div className="col-12">
                            <div className="site-navigation">
                                <div className="site-branding">
                                <a href="#"  onClick={()=>this.goto_page('/')}>
                                            <span className="icon-circle-left" style={{fontSize:"32px",color:"white"}}></span>
                                            
                                        </a>
                                </div>
                               
                            </div>
                        </div>
                    </div>

            <div className="food-cat-title-area bg-image bg-overlay bg-parallax2" style={{padding:"150px 0px 80px 0px", backgroundImage: `url(${MyGlobleSetting.file + this.state.selectedmenu.image})`}}>
               
          
                    
                     <div className="food-cat-title" style={{backgroundColor:"rgb(45 46 47 / 52%)", padding:"10px"}}>{this.state.selectedmenu.name}</div>
            </div>
            <div className="food-menu-block-main ">
               
                <div className="container">

                <div className="row m-2">
                
                <h5 className="food-cat-title" style={{width:"100%",fontSize:"20px",textAlign:"center"}}>Price : <b> {localStorage.getItem("currencyIcon")} {this.state.selectedmenu.price.toFixed(2)}</b></h5>
                <p >{this.state.selectedmenu.description}</p> 
                 </div>

                </div>

 <input type="hidden" name="cart_id" value={this.state.selectedcart.id} />
<input type="hidden" name="id" value={this.state.localtoken} />

<input type="hidden" name="price" value={this.state.selectedmenu.price} />

<input type="hidden" name="menu_id" value={this.state.selectedmenu.id} />
<input type="hidden" name="name" value={this.state.selectedmenu.name} />



                <div className="row m-0" style={{ padding:"0px 10px"}}>


                {this.state.modifierdata!='' && this.state.modifierdata != null ? this.state.modifierdata.map((subdata, i) => {





 if(subdata.type==1)
 {
 var cls="container p-0 mt-3";
 }
 else
 {
    var cls="container p-0 mt-3 display-none";
 }

 var newdata= JSON.parse(subdata.modifier_data);
var optiontext='';
 if(newdata.option_type==0)
 {
    optiontext='(Optional)'; 
 }

 if(newdata.option_type==1)
 {
    optiontext='(Up To '+ newdata.option_value+')'; 
 }


 if(newdata.option_type==2)
 {
    optiontext='(Must Select '+ newdata.option_value+')'; 
 }

var checkedoption=0;

if((newdata.button_type == '1' || newdata.button_type == '2' ) && newdata.button_name != null && newdata.button_name != undefined && newdata.button_name != '')

{
    
    
    (newdata.button_name).map((btn, b) => {


        
 var toggleclass='toggle_'+subdata.id+'_'+b;



    var toggleclassvalue=localStorage.getItem(toggleclass);


     if( toggleclassvalue==1)
     {
        checkedoption++;
     }
 


    });


}


var nomore=0;
if(newdata.option_type!=0 && checkedoption==newdata.option_value)
{
    nomore=1; 
}


var iconmark=1;
if(newdata.option_type!=0)
{
if(newdata.option_type==1 && checkedoption<1)
{
  selectopt=0;

  iconmark=0;
}


if(newdata.option_type==2 && checkedoption!=newdata.option_value)
{
  selectopt=0;

  iconmark=0;
}

}



var trcls="table";
if(this.state.selectedmodifierdata!= subdata.id)
{
    var trcls="table display-none";
}
var bord="1px solid grey";
if(iconmark==0)
{
  bord="3px solid red";
}
 
return (

 
  <div className={cls} style={{border:bord}}>

  <div className=" p-2" style={{background:"lightgrey",fontWeight:"700"}} onClick={()=>this.setState({selectedmodifierdata:subdata.id})}>
  {subdata.name} {optiontext}
  
  {iconmark==1?
  <i class="icon-checkmark ml-2" style={{color:"green",float:"right"}}></i>
  :
  
  <i class="icon-blocked ml-2" style={{color:"red",float:"right"}}></i>
  }
      </div>

    <table className={trcls} style={{marginBottom:"0px"}}>

    { (newdata.button_type == '1' || newdata.button_type == '2' ) && newdata.button_name != null && newdata.button_name != undefined && newdata.button_name != '' ?(newdata.button_name).map((btn, b) => {




var seloption = [];
var afterchargearray = [];


if(subdata.is_charge_after == '1') {
for(var si= 0; si<newdata.button_name.length; si++)
{
  var toggleclass='toggle_'+subdata.id+'_'+si;
  var toggleclassvalue=localStorage.getItem(toggleclass);
  if(toggleclassvalue==1)
  {
    var price = 0;
    if(newdata.price[si]=='' || newdata.price[si]==null || newdata.price[si]==0)
    price=0;
    price=parseFloat(newdata.price[si]);

    seloption.push({"price":price,"index":si });
  }

}


seloption.sort((a,b) => {
  return  a.price-b.price;
});



for(var so=0; so<seloption.length; so++)
{
  if(so < subdata.charge_after_value)
  {
    afterchargearray.push(seloption[so].index);
  }
}

}

if(afterchargearray.includes(b))
{
 newdata.price[b] = 0;
}



if(newdata.price[b]=='' || newdata.price[b]==null || newdata.price[b]==0)
 newdata.price[b]=0;


 newdata.price[b]=parseFloat(newdata.price[b]);




 var toggleclass='toggle_'+subdata.id+'_'+b;

 var qtyclass='qty_'+subdata.id+'_'+b;

 var priceclass='price_'+subdata.id+'_'+b;
    var toggleclassvalue=localStorage.getItem(toggleclass);

    var qtyclassvalue=localStorage.getItem(qtyclass);

    if(qtyclassvalue==undefined)
    {
        qtyclassvalue=1;
    }

var disabled=0;
     if(nomore==1 && (toggleclassvalue==0 || toggleclassvalue==undefined))
     {
         disabled=1;
     }
 
var temp=nomore+'~~'+toggleclassvalue;

if(toggleclassvalue==1)
{
totprice=totprice+(parseFloat(newdata.price[b])*parseInt(qtyclassvalue));
}


return (

    <tr data={toggleclassvalue}>
        <th style={{textAlign:"left"}}>{newdata.button_name[b]} </th>

        <th style={{textAlign:"left"}}>{localStorage.getItem("currencyIcon")}{newdata.price[b].toFixed(2)} </th>

        <td>
            
        {newdata.quantity[b]==1?
       
        <div class="customize-controls">


<div class="qty qty_div">

   <span class="qty-subtract"  onClick={()=>this.subqty(qtyclass,qtyclassvalue)}   ><i class="icon-minus"></i></span>
  <input type="text" name={qtyclass} value={qtyclassvalue} className="quantity"  readonly="" style={{padding:"0px", height:"25px",width:"20px", textAlign:"center"}} />
  <span class="qty-add"  onClick={()=>this.addqty(qtyclass,qtyclassvalue)} ><i class="icon-plus"></i></span>
              </div>

</div>

: 

<input type="hidden" name={qtyclass} value="1" className="quantity"  readonly="" style={{padding:"0px", height:"25px",width:"20px", textAlign:"center"}} />


}

<input type="hidden" name={priceclass} value={newdata.price[b]} className="price" />
</td>

        <td>   <label style={{float:"right"}}>
     
        {disabled==0?
        <Toggle  onChange={this.handleChange} value={toggleclassvalue} className="toggle" name={toggleclass} checked={toggleclassvalue==1?true:false}/>
             :

<Toggle  onChange={this.handleChange2} value={toggleclassvalue} className={toggleclass} name={toggleclass} checked={toggleclassvalue==1?true:false}/>
         
        }
   </label></td>
    </tr>











)

    }) : "" }


</table>

    </div>
)

                }) : "" }

                </div>



             </div>



         </div>

          : "Something went wrong" }




<div class="row customize-controls p-3">


<div class="qty qty_div">

   <span class="qty-subtract"  onClick={()=>this.subqty2()}   ><i class="icon-minus"></i></span>
  <input type="text" name="mainqty" value={this.state.mainqty} className="quantity"  readonly="" style={{padding:"0px", height:"25px",width:"20px", textAlign:"center"}} />
  <span class="qty-add"  onClick={()=>this.addqty2()} ><i class="icon-plus"></i></span>
              </div>


              <br/>
                 


                
              <span class="m-2" style={{fontSize:"20px"}}>
              Sub Total : <b style={{float:"right"}}>{localStorage.getItem("currencyIcon")} {(this.state.mainqty*totprice).toFixed(2)}</b>
<br/>
              
{this.state.selectedcart.surcharge!='' && this.state.selectedcart.surcharge != null  && this.state.selectedcart.surcharge.length>0 ? this.state.selectedcart.surcharge.map((attr, i) => {

if(attr.name!= '' && attr.name != null) {
var val=0;
  if(attr.type==0)
  {
    val = this.state.mainqty*attr.value;
  } else {
    val = (parseFloat(totprice)*parseFloat(this.state.mainqty)*attr.value)/100;
  }
  sur +=val;
  return (
<span>


{attr.name} {attr.type == 1 ? "("+attr.value+"%)":"" } :&nbsp;<b style={{float:"right"}}>{localStorage.getItem("currencyIcon")} {val.toFixed(2)}</b><br/>
</span> 
  )
}
}):""}




<span>Total : <b style={{float:"right"}}>{localStorage.getItem("currencyIcon")} {((this.state.mainqty*totprice)+sur).toFixed(2)}</b></span>  

                
           

                
               
                
                
                
                </span>
</div>






<div><input type="hidden" name="tprice" value={(totprice)} />
</div>







<div className=" pl-2 pr-2">
<label><b>Special Instructions</b></label>
<center>

<textarea className="form-control" name="textarea" value={this.state.textarea} onChange={(e)=>this.setState({textarea:e.target.value})}placeholder="Special Instructions" rows="1"></textarea>

  </center>
</div>


    <center>
    {selectopt==1?
<button className="btn btn-primary mt-3" type="submit" style={{width:"95%"}}>Add To Cart</button>

: <button className="btn btn-primary mt-3" type="button" onClick={()=>this.openmodaloption()} style={{width:"95%"}}>Add To Cart</button>
}
</center>

</form>

        </div>





    </div>
  );
}
}

export default Edit;
