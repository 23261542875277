import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";

import CardSection from "./CardSection";
import axios from 'axios';
import Toggle from 'react-toggle'
import swal from 'sweetalert';
import $ from 'jquery';

import MyGlobleSetting from './MyGlobleSetting';
class StripeCheckoutForm extends React.Component {



  handleSubmit = async event => {
    document.body.classList.add('loading-indicator');
    event.preventDefault();

    const { stripe, elements ,data, textarea} = this.props;
    if (!stripe || !elements) {
      document.body.classList.remove('loading-indicator');
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      document.body.classList.remove('loading-indicator');
      console.log(result.error.message);
    } else {

      
      
     this.stripepay(result.token.id);
    }
  };



  
  stripepay(id)
  {

    var token=localStorage.getItem("devicetoken");
    var tabledata = localStorage.getItem("tableid");

 
    if(token=='' && tabledata=='')
    {
      swal({
   
        text: "Invalid data",
       
      });
      return false;
    }
    const data = {
    
        token: token,
        tableid: tabledata,
      
        textarea:this.props.textarea,
        stripeToken:id,
        amount:parseFloat(this.props.data).toFixed(2),
        stripetype:'card'
        
      }
    axios.post(MyGlobleSetting.api+'/api/stripepay', data).then((response) => {
      document.body.classList.remove('loading-indicator'); 
     if(response.data.success==true) 
     
     {
       swal({
       
        text: "Your order is successfully created  !!",
        icon: "success"
       
      });
      //localStorage.clear();
    window.location.href="/thanks";

    }
    else
    {
      swal({
       
        text: "Payment Failed !!",
        icon: "error"
       
      });
    }


    });
         

  }






  render() {
    return (
      <div>
      
        <form onSubmit={this.handleSubmit}>
          <CardSection />
          <button disabled={!this.props.stripe} className="btn-pay">
           Pay {this.props.amount}
          </button>
        </form>
      </div>
    );
  }
}

export default function InjectedCheckoutForm(props) {

  var data = props.data;
  var textarea = props.textarea;
  return (
    <ElementsConsumer>
      {({ stripe, elements}) => (
        <StripeCheckoutForm stripe={stripe} textarea={textarea} elements={elements} data={data} />
      )}
    </ElementsConsumer>
  );
}