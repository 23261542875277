import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './Home';
import Slide from './Slide';
import Cart from './Cart';
import Edit from './Edit';
import ScanQR from './ScanQR';
import Thanks from './Thanks';
import CheckoutForm from './CheckoutForm';
import View from './View';
import StripeCheckoutForm from './StripeCheckoutForm';
import Qroff from './Qroff';
import Restaurantclosed from './Restaurantclosed';
import PageNotFound from './PageNotFound';
class App extends Component {
  render() {
    return (
    <Router>
     
       
      

            <Switch>
            <Route exact path='/slide' component={Slide} />
              <Route exact path='/' component={Home} />
              <Route exact path='/cart' component={Cart} />
              <Route exact path='/edit' component={Edit} />
              <Route exact path='/ScanQR' component={ScanQR} />
              <Route exact path='/thanks' component={Thanks} />
              <Route exact path='/view' component={View} />
              <Route exact path='/qroff' component={Qroff} />
              <Route exact path='/restaurantclosed' component={Restaurantclosed} />
              <Route exact path='/CheckoutForm' component={CheckoutForm} />
              <Route exact path='/StripeCheckoutForm' component={StripeCheckoutForm} />
              <Route path="*" component={PageNotFound} />
             
            </Switch>
       
      </Router>
    );
  }
}

export default App;